import { ReactNode } from 'react';

export default function BaseSkeleton({
  isLoaded,
  children,
  className = '',
}: IProps) {
  return (
    <>
      {isLoaded && (
        <div className={className}>
          {!children && (
            <div className="animate-pulse flex space-x-4 ">
              <div className="rounded-full bg-slate-200 h-10 w-10 bg-backgroundAuxiliaryColor"></div>
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-200 rounded bg-backgroundAuxiliaryColor"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-200 bg-backgroundAuxiliaryColor rounded col-span-2"></div>
                    <div className="h-2 bg-slate-200 bg-backgroundAuxiliaryColor rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-200 rounded"></div>
                </div>
              </div>
            </div>
          )}
          <div className="animate-pulse">{children}</div>
        </div>
      )}
    </>
  );
}

interface IProps {
  isLoaded: boolean;
  children?: ReactNode;
  className?: string;
}
